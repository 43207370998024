module.exports = ({
    // "CDMS400.CONFIRM.TITLE": "確認",
    // "CDMS400.LISTBOX.TITLE": "施設選択",
    // "CDMS400.TITLE": "症例一覧",
    // "CDMS400MSG002": "被験者を有効化します。\nよろしいですか。",
    // "CDMS400MSG003": "被験者を無効化します。\nよろしいですか。",
    // "CDMS400MSG004": "登録ページが存在しません。",
    // "CDMS400MSG005": "複数の登録ページが設定されています。",
    // "CDMS400MSG006": "登録ページにScreening numberが設定されていません。",
    // "CDMS400MSG007": "登録ページのScreening numberのレイアウトが適切に設定されていません。",
    // "CDMS400MSG008": "登録ページのEnrollment numberのレイアウトが適切に設定されていません。",
    // "CDMS400MSG009": "登録ページにEnrollment numberとScreening numberが同じ項目に設定されています。",
    // "CDMS400MSG010": "施設のバージョンが正しく設定されないため、症例を登録できません。",
    // "CDMS400.SITENAME": "施設名",
    // "CDMS400.STATUS": "ステータス",
    // "CDMS400.STATUS.SAVED": "保存済",
    // "CDMS400.REGISTER.DATE": "登録日",
    // "CDMS400.SUBJECTNO": "被験者番号",
    // "CDMS400.SITECODE": "施設コード",
    // "CDMS400.OPENQUERY": "オープンクエリー数",
    // "CDMS400.BTN.DOWNLOAD": "ダウンロード",
    // "CDMS400.BTN.ENDABLE": "無効化",
    // "CDMS400.BTN.DISABLE": "再有効化",
    // "CDMS400.BTN.ADD.SUBJECT": "被験者追加",

    "CDMS400TT001": "Subject List",
    "CDMS400TT002": "Site Selection",
    "CDMS400BTN001": "Add Subject",
    "CDMS400LB001": "Site ID",
    "CDMS400LB002": "Site Name",
    "CDMS400LB003": "Subject ID",
    "CDMS400LB004": "System Register Day",
    "CDMS400LB005": "Number of Open Query",
    "CDMS400LB006": "Status",
    "CDMS400OPT001": "Contain Open Query",
    "CDMS400OPT002": "All Queries got answered",
    "CDMS400OPT003": "Lock completed",
    "CDMS400OPT004": "e-Signature completed",
    "CDMS400OPT005": "Review completed",
    "CDMS400OPT006": "SDV completed",
    "CDMS400OPT007": "Freeze completed",
    "CDMS400OPT008": "Contain unsaved page",
    "CDMS400OPT009": "All pages got saved",

    "CDMS400MSG001": "Are you sure you want to enable the selected subjects?",
    "CDMS400MSG002": "Are you sure you want to disable the selected subjects?",
    "CDMS400MSG003": "Registration Page is not existing.",
    "CDMS400MSG004": "Multiple Registration Pages are existing.",
    "CDMS400MSG005": "Screening Number is not set for the Registration Page.",
    "CDMS400MSG006" : "Are you sure you want to re-execute ECS?",
    "CDMS400MSG007" : "ECS cannot be run because there's a disabbled subject",
    "CDMS400MSG008": "The subject cannot be registered because the site version is not set properly.",
    "COMMSG023": "The server is overloading. Please wait a bit and try again."
});