export const API_BASE_URL = 'http://localhost:8080';
export const ACCESS_TOKEN = 'accessToken';
export const CURRENT_USER = 'currentUser';
export const SELECTED_LOCALE = 'selectedLocale';
export const DEFAULT_LOCALE = 'ja-JP';
export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 10;
export const PAGE_DEFAULT = 0;
export const PAGE_SIZE_LIST = [10, 20, 50];
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_LANGUAGE = 3;
export const DEFAULT_TIME_UTC = 9;
export const DEFAULT_COUNTRY_CODE = "JP";
export const SITE_CODE_NO_RESTRICTION = -1;
export const KEY_STUDY_DB_LOCK = "studyDBLock_";
export const EDC_SESSION_NAME = "_edc_session";
export const NOTIFICATION_ID_DISPLAYED_LIST = "notificationIdDisplayedList";

export function getStatusFilterList(intl) {
    return [
        {
            'label': intl.get('CDMS400OPT001'),
            'value': 1
        },
        {
            'label': intl.get('CDMS400OPT002'),
            'value': 2
        },
        {
            'label': intl.get('CDMS400OPT003'),
            'value': 3
        },
        {
            'label': intl.get('CDMS400OPT004'),
            'value': 4
        },
        {
            'label': intl.get('CDMS400OPT005'),
            'value': 5
        },
        {
            'label': intl.get('CDMS400OPT006'),
            'value': 6
        },
        {
            'label': intl.get('CDMS400OPT007'),
            'value': 7
        },
        {
            'label': intl.get('CDMS400OPT008'),
            'value': 8
        },
        {
            'label': intl.get('CDMS400OPT009'),
            'value': 9
        }
    ]
}

export const LIST_STATUS_FILTER = [
    {
        'label': 'CDMS400OPT001',
        'value': 1
    },
    {
        'label': 'CDMS400OPT002',
        'value': 2
    },
    {
        'label': 'CDMS400OPT003',
        'value': 3
    },
    {
        'label': 'CDMS400OPT004',
        'value': 4
    },
    {
        'label': 'CDMS400OPT005',
        'value': 5
    },
    {
        'label': 'CDMS400OPT006',
        'value': 6
    },
    {
        'label': 'CDMS400OPT007',
        'value': 7
    },
    {
        'label': 'CDMS400OPT008',
        'value': 8
    },
    {
        'label': 'CDMS400OPT009',
        'value': 9
    }
]

export const USER_STATUS_NOT_YET = 1
export const USER_STATUS_LOCK = 2
export const USER_STATUS_IN_ACTIVE = 3
export const USER_STSTUS_ACTIVE = 4

export const DB_STUDY_USER_ROLE_AUDITLOG_STATUS_ACTIVE      = 1;
export const DB_STUDY_USER_ROLE_AUDITLOG_STATUS_INACTIVE    = 0;

export function listDataStatus() {
    return [
        {label: "Active", value: USER_STSTUS_ACTIVE},
        {label: "Inactive", value: USER_STATUS_IN_ACTIVE},
        {label: "Locked", value: USER_STATUS_LOCK},
        {label: "Not yet", value: USER_STATUS_NOT_YET}
    ]
}

export const DB_QUERY_STATUS_OPEN = 1;
export const DB_QUERY_STATUS_CANCELED = 2;
export const DB_QUERY_STATUS_ANSWERED = 3;
export const DB_QUERY_STATUS_CLOSED = 4;
export const DB_QUERY_STATUS_REOPENED = 5;

export const DB_QUERY_TYPE_SYSYEM = 1;
export const DB_QUERY_TYPE_MANUAL = 2;

//Menu
export const ADMIN_MENU_VIEW    = "admin_menu_view"
export const STATUS_MENU_VIEW   = "status_menu_view"
export const DATA_MENU_VIEW   = "data_menu_view"

export const SUBJECT_LIST_VIEW = 'subject_list_view';
export const USER_LIST_VIEW = 'user_list_view';
export const SITE_LIST_VIEW = 'site_list_view';
export const STUDY_INFO_VIEW = 'study_info_view';
export const SITE_CREATE = 'site_create';
export const SITE_EDIT = 'site_edit';
export const SITE_ENABLE = 'site_enable';
export const SITE_LIST_DOWNLOAD = 'site_list_download';
export const SITE_AUDIT_VIEW = 'site_audit_view';
export const DATA_SET_DOWNLOAD = 'data_set_download'
export const DATA_EXPORT = 'data_export'
export const USER_HISTORY_REPORT    = 'user_history_report'
export const ECONSENT_HISTORY_VIEW = 'econsent_history_view'
// USER
export const USER_DISABLE           = "user_disable"
export const USER_RESET_PASSWORD    = "user_reset_password"
export const USER_REGISTER          = "user_register"
export const USER_EDIT              = "user_edit"
export const USER_AUDIT_VIEW        = "user_audit_view"
export const USER_ROLE_EDIT         = "user_role_edit"
export const USER_ROLE_AUDIT_VIEW   = "user_role_audit_view"

export const FREEZE_EDIT    = 'FREEZE_EDIT'
export const FREEZE_VIEW    = 'FREEZE_VIEW'
export const SDV_EDIT       = 'SDV_EDIT'
export const SDV_VIEW       = 'SDV_VIEW'
export const REVIEW_EDIT    = 'REVIEW_EDIT'
export const REVIEW_VIEW    = 'REVIEW_VIEW'
export const LOCK_EDIT      = 'LOCK_EDIT'
export const LOCK_VIEW      = 'LOCK_VIEW'
export const SIGNATURE_EDIT = 'ESIGN_EDIT'
export const SIGNATURE_VIEW = 'ESIGN_VIEW'

export const SUBJECT_DISABLE = 'SUBJECT_DISABLE'

export const ECS_RE_EXECUTE = 'ECS_RE_EXECUTE'
export const RE_RUN_ECS = 'RE_RUN_ECS'

export const ETRAINING_CERT_DOWNLOAD = 'ETRAINING_CERT_DOWNLOAD'
/**
 *
 * @returns {*[]}
 */
export function listMasterDataStatusScreen(intl) {
    return [
        {label: intl.get('CDMS706OPT001'), value: 0},
        {label: intl.get('CDMS706OPT002'), value: 1},
        {label: intl.get('CDMS706OPT003'), value: 2},
        {label: intl.get('CDMS706OPT004'), value: 3},
    ]
}
export function listMasterDataStatusQuestionScreen(intl) {
    return [
        {label: intl.get('CDMS706OPT001'), value: 0},
        {label: intl.get('CDMS706OPT003'), value: 2},
        {label: intl.get('CDMS706OPT004'), value: 3},
    ]
}

export function listMasterDataInputStatus(intl) {
    return [
        {label: intl.get('CDMS706OPT001'), value: 0},
        {label: intl.get('CDMS706OPT003'), value: 2}
    ]
}

export function listMasterDataInputStatus700To702(intl) {
    return [
        {label: intl.get('CDMS706OPT001'), value: 0},
        {label: intl.get('CDMS706OPT002'), value: 1},
        {label: intl.get('CDMS706OPT003'), value: 2}
    ]
}

// ETRAINING
export const ETRAINING_CERT_DOWNLOADED = 1;
export const ETRAINING_CERT_UNDOWNLOADED = 0;

export function listEtrainingStatus(intl) {
    return [
        { label: intl.get('CDMS300OPT001'), value: ETRAINING_CERT_DOWNLOADED },
        { label: intl.get('CDMS300OPT002'), value: ETRAINING_CERT_UNDOWNLOADED },
    ]
}

export function listETrainingCompletionStatus(intl) {
    return [
        { label: intl.get('LABEL-COMPLETED'), value: ETRAINING_CERT_DOWNLOADED },
        { label: intl.get('LABEL-INCOMPLETED'), value: ETRAINING_CERT_UNDOWNLOADED },
    ]
}

export function listUserActiveStatus(intl) {
    return [
        {
            label: intl.get("LABEL-ACTIVE"),
            value: DB_STATUS_CHECKED_FLAG.DB_STATUS_CHECKED_FLAG,
        },
        {
            label: intl.get("LABEL-INACTIVE"),
            value: DB_STATUS_CHECKED_FLAG.DB_STATUS_UNCHECKED_FLAG,
        },
    ];
}


export const ALL_INPUT_STATUS_FLAG = [0, 1, 2]
export const ALL_INPUT_STATUS_FOR_PAGE_DOWN = [0, 2]
export const ALL_STATUS_FLAG = [0, 1, 2, 3];
//exportStatus: 1: new, 2: progress, 3:success, 4: failed
export const DATASET_EXPORT_STATUS_NEW = 1;
export const DATASET_EXPORT_STATUS_INPROGRESS = 2;
export const DATASET_EXPORT_STATUS_SUCCESS = 3;
export const DATASET_EXPORT_STATUS_FAIL = 4;
export const DATASET_EXPORT_STATUS_NONE = 5;

//Menu setting
export const LISTMENU = {
    ETRAINING : "e-training",
    SUBJECT: "subject",
    QUERY: "query",
    STATUS_MENU_VIEW: "status-menu-view",
    STATUS_SITE: "status-site",
    STATUS_SUBJECT: "status-subject",
    STATUS_VISIT: "status-visit",
    STATUS_PAGE: "page-status",
    STATUS_BLOCK: "block-status",
    STATUS_LINE: "line-status",
    STATUS_QUESTION: "question-status",
    DATA_SET : "download-dataset",
    USER_HISTORY_REPORT : "user-history-report",
    ECRF : "eCrf",
    SUBJECT_PDF : "subject-pdf",
    SUBJECT_PDF_HISTORY : "subject-pdf-history",
    ADMIN_SITE : "site",
    ADMIN_USER : "user",
    ADMIN_INFO : "exam-info",
    DATA_SET_DOWNLOAD :  'download-dataset',
    DB_LOCK :  'db-lock',
    ECONSENT :  'econsent',
    ECS_SERVICE : "ecs_history_view",

}

export const LIST_POPUP_ELEMENT = {
    YES: 'YES',
    NO: 'NO',
}

export const ROLES = {
    USER_EDIT : "USER_EDIT"
}

export const ITEM_DATA_TYPE_NUMBER = 2;
//error code when db-lock
export const DB_LOCKED_ERROR_CODE = 419;
//
export const MAX_LENGTH_DATA_SCRNUM = 256;

export function ipDeliveryStatusList(intl){
    return [
        { label: intl.get('CDMS941OPT1'), value: 0 },
        { label: intl.get('CDMS941OPT2'), value: 1 }
    ]
}
export const ARM_RESET = 'arm_reset';
export const ARM_IMPORT = 'arm_import';
export const ARM_DOWNLOAD = 'arm_download';
export const IP_RESET = 'ip_reset';
export const IP_IMPORT = 'ip_import';
export const IP_DOWNLOAD = 'ip_download';
export const IWRS = 'IWRS';
export const IWRS_MENU_VIEW = "iwrs_menu_view";

export const IWRS_MENU = {
    LIST_UPLOAD: "list_upload_view",
    DELIVERY_DRUG: "delivery_drug_view",
    BLIND_LIST: "blind_list_view"
}

export const SITE_IWH_INFO = { id: 0, siteName: "IWH施設" };
export const MESSAGE_CODE_DATA_HAS_CHANGE = "COMMSG013";

export const BLIND_STATUS_UN_BLIND = 0;
export const BLIND_STATUS_WAITING_FOR_APPROVE = 1;
export const BLIND_STATUS_WAITING_FOR_UN_BLIND = 2;
export const BLIND_STATUS_BLIND = 3;

export const ITEM_PROPERTY_TYPE = {
    ITEM_PROPERTY_TYPE_AUTOFILL: 1,
    ITEM_PROPERTY_TYPE_DEFAULT: 2,
    ITEM_PROPERTY_TYPE_UNIT_UPPER: 3,
    ITEM_PROPERTY_TYPE_UNIT_BOTTOM: 4,
    ITEM_PROPERTY_TYPE_UNIT_LEFT: 5,
    ITEM_PROPERTY_TYPE_UNIT_RIGHT: 6,
    ITEM_PROPERTY_TYPE_EXCLUDE: 7,
    ITEM_PROPERTY_TYPE_SCRNUM: 8,
    ITEM_PROPERTY_TYPE_ENRNUM: 9,
    ITEM_PROPERTY_TYPE_RANDOM_KEY: 10,
    ITEM_PROPERTY_TYPE_ARM_GENERATE: 11,
    ITEM_PROPERTY_TYPE_IP_GENERATE: 12,
    ITEM_PROPERTY_TYPE_STRATIFICATION: 13,
    ITEM_PROPERTY_TYPE_HIDDEN: 14
}

// INCOMPLETED = 0
export const DB_STATUS_INPUT_FLAG = {
    // INCOMPLETED
    DB_STATUS_INPUT_FLAG_UN_INPUT: 0,
    //PARRIALLY_COMPLETED
    DB_STATUS_INPUT_FLAG_PARTITIAL_INPUT: 1,
    // COMPLETED
    DB_STATUS_INPUT_FLAG_ALL_INPUT: 2
}

//   UN-CHECK = 0
export const DB_STATUS_CHECKED_FLAG = {
    // UN-CHECK
    DB_STATUS_UNCHECKED_FLAG: 0,
    // CHECKED
    DB_STATUS_CHECKED_FLAG: 1,
}

// 割付の種類
export const DB_ALLOCATION_TYPE = {
    STATIC_STRATIFICATION: 0,
    DYNAMIC_STRATIFICATION: 1
}

export function listMasterDataSystemLinkStatus(intl) {
    return [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 }
    ]
}

export const ROLE_TO_MATRIX_VIEW = [
    'CASE_REGISTER',
    'CASE_DELETE',
    'PAGE_INITIAL',
    'CRF_VIEW',
    'CRF_EDIT',
    'QUERY_ISSUE',
    'QUERY_VIEW',
    'QUERY_ANSWER',
    'COMMENT_ADD',
    'FREEZE_VIEW',
    'FREEZE_EDIT',
    'SDV_VIEW',
    'SDV_EDIT',
    'REVIEW_VIEW',
    'REVIEW_EDIT',
    'LOCK_VIEW',
    'LOCK_EDIT',
    'SIGNATURE_VIEW',
    'SIGNATURE_EDIT'
]
