module.exports = ({
    "CDMS700P001": "ユーザーIDを入力してください",
    "CDMS700P002": "パスワードを入力してください",
    "CDMS700MSG001": "ユーザーIDを入力してください。",
    "CDMS700MSG002": "パスワードを入力してください。",
    "CDMS700MSG003": "ユーザーIDまたはパスワードが異なります。",
    "CDMS700MSG004": "一部のデータが変更されたため、更新できません。ページをリフレッシュしてください。",
    "CDMS700MSG005": "{level}の中にLockされた対象がありましたため、その対象に関係ないものだけでステータスが設定されました。",
    "CDMS706H001": "施設",
    "CDMS706H002": "被験者番号",
    "CDMS706H003": "Visit",
    "CDMS706H004": "ページ",
    "CDMS706H005": "ブロック",
    "CDMS706H006": "ラインNo.",
    "CDMS706H007": "質問",
    "CDMS706H008": "入力状況",
    "CDMS706H009": "オープンクエリー",
    "CDMS706H010": "クエリー回答",
    "CDMS706H011": "電子署名",
    "CDMS706H012": "Freeze",
    "CDMS706H013": "SDV",
    "CDMS706H014": "Review",
    "CDMS706H015": "Lock",
    "CDMS700TT001": "ステータス（施設）",
    "CDMS701TT001": "ステータス（被験者）",
    "CDMS702TT001": "ステータス（Visit）",
    "CDMS703TT001": "ステータス（ページ）",
    "CDMS704TT001": "ステータス（ブロック）",
    "CDMS705TT001": "ステータス（ログライン）",
    "CDMS706TT001": "ステータス（質問）",
    "CDMS706OPT001": "未完了",
    "CDMS706OPT002": "一部完了",
    "CDMS706OPT003": "完了",
    "CDMS706OPT004": "N/A",
    "CDMS706LB001": "※私はパスワードを入力することにより選択されたフォームの全てのデータが適用される規制に準拠していることを保証いたします。",
    "CDMS706LB002": "ユーザーID",
    "CDMS706LB003": "パスワード",
});