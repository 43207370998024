module.exports = ({

    "CDMS402MSG011":"The page you are saving cannot be saved.",

    "CDMS402.MODAL.EDIT" : "修正理由",
    "CDMS402.BUTTON.VERIFI_HANDLE_STAY" : "保存後、新規症例追加",
    "CDMS402.BUTTON.VISIT_FORM_MATRIX" : "Visit&Form Matrix",
    "CDMS402.BUTTON.NEXT_PAGE" : "次のページ",
    "CDMS402.BUTTON.LIST_TO_SUBJECT" : "被験者一覧へ",
    "CDMS402.BOX.CREATE_QUERY": "クエリー作成：",
    "CDMS402.BOX.SELECT_CREATE_QUERY": "クエリーを作成した項目を選択して下さい。",
    "CDMS402.BOX.QUERY_CONTENT": "クエリーの内容を入力して下さい。",
    "CDMS402.BOX.REQUIRED_CONTENT": "クエリーの内容を入力してください。",
    "CDMS402.COMMENT.PLACEHOLDER": "コメント...",
    "CDMS402.COMMENT.BUTTON.DELETE": "削除",
    "CDMS402.COMMENT.BUTTON.EDIT": "修正",
    "CDMS402.COMMENT.LABEL": "【コメント】",

    "CDMS402.AUDITTRAIL.TABLE.DATE": "日付",
    "CDMS402.AUDITTRAIL.TABLE.USER": "ユーザー（使用者）",
    "CDMS402.AUDITTRAIL.TABLE.LABEL": "ラベル",
    "CDMS402.AUDITTRAIL.TABLE.BEFORE": "以前",
    "CDMS402.AUDITTRAIL.TABLE.NEXT": "次",
    "CDMS402.AUDITTRAIL.TABLE.REASON": "理由",
    "CDMS402.AUDITTRAIL.TABLE.REASON.QUERY": "回答",

    "CDMS402.QUERY.TABLE.ITEM": "項目",
    "CDMS402.QUERY.TABLE.GROUP_NUMBER": "グループ番号",
    "CDMS402.QUERY.TABLE.DATE": "日付",
    "CDMS402.QUERY.TABLE.TYPE": "タイプ",
    "CDMS402.QUERY.TABLE.QUERY": "クエリー",
    "CDMS402.QUERY.TABLE.STATUS": "状態",
    "CDMS402.QUERY.EDIT.DELETE": "削除",
    "CDMS402.QUERY.EDIT.CONTENT": "内容編集",
    "CDMS402.QUERY.EDIT.ERROR": "クエリーの内容を入力してください。",

    "CDMS402.COMMENT.TABLE.CONTENT": "コメント",

    "CDMS402.FREEZE.TABLE.QUESTION": "質問",
    "CDMS402.FREEZE.TABLE.DATE": "日付",
    "CDMS402.FREEZE.TABLE.USER": "ユーザー（使用者）",
    "CDMS402.FREEZE.TABLE.STATUS": "状態",
    "CDMS402.QUERY.REOPEN": "再オープン",

    //401
    "CDMS401P001": "Comment...",
    "CDMS401MSG001": "{label} is empty.",
    "CDMS401MSG002": "Data can not be saved because open queries have issued.",
    "CDMS401MSG003": "{type} cannot be increased automatically.",
    "CDMS401MSG004": "Are you sure you want to add a new subject?",
    "CDMS401MSG005": "The Subject ID is already in use.",
    "CDMS401BTN001": "Edit",
    "CDMS401LB001": "【Comment】",
    "CDMS400MSG009": "Screening number must be less than or equal to 256 characters.",
    "CDMS400MSG010": "Enrollment number must be less than or equal to 256 characters.",
    
    //402
    "CDMS402MSG001": "Please enter query content.",
    "CDMS402MSG002": "Please enter answer content.",
    "CDMS402MSG003": "{count} open queries have issued.",
    "CDMS402MSG004": "Please enter reason of modification.",
    "CDMS402MSG005": "Are you sure you want to save the page?",
    "CDMS402BTN001": "Subject List",
    "CDMS402BTN002": "Next Page",
    "CDMS402BTN003": "Edit",
    "CDMS402BTN004": "Answer Query",
    "CDMS402BTN005": "Edit Answer",
    "CDMS402BTN006": "Close/Reopen Query",
    "CDMS402BTN007": "Visit&Form Matrix",
    "CDMS402LB001": "【Query】",
    //"CDMS402LB002":
    "CDMS402LB003": "If multiple data modifications have been performed, please select「Yes」if you want to use the same reason, and select 「No」 if you do not.",
    "CDMS402LB004": "Please select an item that you want to issue new query for.",
    "CDMS402LB005": "Please enter query content.",
    "CDMS402LB006": "Please select a query confirmation result",
    "CDMS402LB007": "Line {line}",
    "CDMS402TT001": "Reason of Modification",
    "CDMS402TT002": "Issue Query",
    "CDMS402TT003": "Answer",
    "CDMS402TT004": "Answer Modification",
    "CDMS402OPT001": "Input Error",
    "CDMS402OPT002": "New Data",
    "CDMS402OPT003": "Changed Source Data",
    "CDMS402OPT004": "Others",
    "CDMS402OPT005": "Delete",
    "CDMS402OPT006": "Edit Answer",
    "CDMS402OPT007": "Close Query",
    "CDMS402OPT008": "Issue Query(Reopen Query)",
    "CDMS402OPT009": "System",
    "CDMS402OPT010": "Manual",
    "CDMS402OPT011": "Open",
    "CDMS402OPT012": "Answered ",
    "CDMS402OPT013": "Closed",
    "CDMS402OPT014": "Reopen",

    "CDMS402HL001": "Data Audit Trail",
    "CDMS402HL002": "Query Audit Trail",
    "CDMS402HL003": "Comment Audit Trail",
    "CDMS402HL004": "Signature Audit Trail",
    "CDMS402HL005": "SDV Audit Trail",
    "CDMS402HL006": "Review Audit Trail",
    "CDMS402HL007": "Lock Audit Trail",
    "CDMS402HL008": "Freeze Audit Trail",

    "CDMS402H001": "Datetime",
    "CDMS402H002": "User",
    "CDMS402H003": "Label",
    "CDMS402H004": "Before",
    "CDMS402H005": "After",
    "CDMS402H006": "Reason",
    "CDMS402H007": "Item",
    "CDMS402H008": "Group No.",
    "CDMS402H009": "Type",
    "CDMS402H010": "Query",
    "CDMS402H011": "Status",
    "CDMS402H012": "Answer",
    "CDMS402H013": "Comment",
    "CDMS402H014": "Question",
    "CDMS402H015": "No.",

    "CDMS402MSG006": "The status of this level cannot be set because its item has been locked.",
    "CDMS500MSG001": "You cannot input data for this page because the registration page already exists",

    "CDMS402MSG007": "Subject has been randomized. Data has been saved.",
    "CDMS402MSG008": "Can not find out Stratification Group for this Subject",
    "CDMS402MSG009": "Can not find out any ARM for this Subject",
    "CDMS402MSG010": "Can not find out any IP No for this Subject",
    "CDMS402MSG012": "Subject has been randomized. But, other items of this page cannot be saved.",
    "CDMS402MSG015": "Are you sure you want to randomize this Subject?",
});