module.exports = ({
    "CDMS600MSG001": "Are you sure you want to close the selected queries?",
    "CDMS600MSG002": "Please enter query content.",
    "CDMS600MSG003": "Are you sure you want to reopen the selected queries?",
    "CDMS600MSG004": "Are you sure you want to edit content of the selected queries?",

    "CDMS600BTN001": "Batch Process",
    "CDMS600BTN002": "Execute",

    "CDMS600H001": "No.",
    "CDMS600H002": "Site Name",
    "CDMS600H003": "Subject ID",
    "CDMS600H004": "Visit",
    "CDMS600H005": "Page",
    "CDMS600H006": "Block",
    "CDMS600H007": "Line No.",
    "CDMS600H008": "Item",
    "CDMS600H009": "Status",
    "CDMS600H010": "Query Type",
    "CDMS600H011": "Query Content",
    "CDMS600H012": "Answer Content",
    "CDMS600H013": "Issuer",
    "CDMS600H014": "Last Updated Time",

    "CDMS600TT001": "Query Management",
    "CDMS600TT002": "Batch Process",

    "CDMS600OPT001": "Close Query",
    "CDMS600OPT002": "Reopen Query",
    "CDMS600OPT003": "Edit Query",
    "CDMS600OPT004": "Open",
    "CDMS600OPT005": "Closed",
    "CDMS600OPT006": "Answered",
    "CDMS600OPT007": "System",
    "CDMS600OPT008": "Manual",
    "CDMS600OPT009": "System",
});