import intl from "react-intl-universal";
import React, { Component } from 'react';
import './css/fonts.css';
import './css/App.css';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { notification, ConfigProvider } from 'antd';

import * as CommonApi from "util/api/CommonApi";
import { getCurrentUser } from 'util/api/UserApi';
import { getStudySetting } from 'util/api/StudyApi';
import { DEFAULT_LOCALE, IWRS_MENU, NOTIFICATION_ID_DISPLAYED_LIST, SELECTED_LOCALE } from 'constants/index';
import { ACCESS_TOKEN, CURRENT_USER } from 'constants/index';

import NotFound from 'common/NotFound';
import LoadingComponent from 'common/LoadingComponent';
import { currentDateTime, compareTwoDate } from 'util/Helpers';
import moment from 'moment';
import 'moment/locale/ja'
import jaJP from 'antd/lib/locale-provider/ja_JP';
import enUS from 'antd/lib/locale-provider/en_US';

import Loadable from 'react-loadable';
import { LISTMENU, IWRS } from "constants/index";
import { FilterCacheApp } from "util/FilterHelpers";

const LoginLayoutRoute = Loadable({
	loader: () => import("common/LoginLayout"),
	loading: LoadingComponent
});

const MainLayoutRoute = Loadable({
	loader: () => import("common/MainLayout"),
	loading: LoadingComponent
});

const ForgetLayoutRoute = Loadable({
	loader: () => import("common/ForgetLayout"),
	loading: LoadingComponent
});

const Login = Loadable({
	loader: () => import("components/user/login/Login"),
	loading: LoadingComponent
});

const PasswordSetting = Loadable({
	loader: () => import("components/user/password/PasswordSetting"),
	loading: LoadingComponent
});

const ForgetLoginId = Loadable({
	loader: () => import("components/user/forget/ForgetLoginId"),
	loading: LoadingComponent
});

const ForgetPassword = Loadable({
	loader: () => import("components/user/forget/ForgetPassword"),
	loading: LoadingComponent
});

const SubjectList = Loadable({
	loader: () => import("components/subject/SubjectList"),
	loading: LoadingComponent
});

const Page = Loadable({
	loader: () => import("components/page/Page"),
	loading: LoadingComponent
});

const Study = Loadable({
	loader: () => import("components/study/StudyList"),
	loading: LoadingComponent
});

const ETrainingSlide = Loadable({
	loader: () => import("components/etraining/ETrainingSlide"),
	loading: LoadingComponent
});

const VisitMatrix = Loadable({
	loader: () => import("components/visitmatrix/Index"),
	loading: LoadingComponent
});


const StudyUser = Loadable({
	loader: () => import("components/studyUser/index"),
	loading: LoadingComponent
});

const StudyUserAdd = Loadable({
	loader: () => import("components/studyUser/add/StudyUserAdd"),
	loading: LoadingComponent
});

const StudyUserEdit = Loadable({
	loader: () => import("components/studyUser/edit/StudyUserEdit"),
	loading: LoadingComponent
});

const SiteList = Loadable({
	loader: () => import("components/site/SiteList"),
	loading: LoadingComponent
});

const SiteRegister = Loadable({
	loader: () => import("components/site/SiteRegister"),
	loading: LoadingComponent
});

const SiteEdit = Loadable({
	loader: () => import("components/site/SiteEdit"),
	loading: LoadingComponent
});
const VisitStatus = Loadable({
	loader: () => import("components/status/visitstatus/VisitStatus"),
	loading: LoadingComponent
});
const SubjectStatus = Loadable({
	loader: () => import("components/status/subjectstatus/SubjectStatus"),
	loading: LoadingComponent
});
const SiteStatus = Loadable({
	loader: () => import("components/status/sitestatus/SiteStatus"),
	loading: LoadingComponent
});

const Dataset = Loadable({
	loader: () => import("components/data/Dataset"),
	loading: LoadingComponent
});

const DatasetHistory = Loadable({
	loader: () => import("components/data/DatasetHistoriesList"),
	loading: LoadingComponent
});

const UserHistoryReport = Loadable({
	loader: () => import("components/data/userHistoryReport/UserHistoryReportList"),
	loading: LoadingComponent
});

/**
 * Status Module
 */
const PageStatus = Loadable({
	loader: () => import("components/status/page/PageStatusList"),
	loading: LoadingComponent
});

const BlockStatus = Loadable({
	loader: () => import("components/status/block/BlockStatusList"),
	loading: LoadingComponent
});

const QuesionStatus = Loadable({
	loader: () => import("components/status/question/QuestionStatusList"),
	loading: LoadingComponent
});

const LineStatus = Loadable({
	loader: () => import("components/status/line/LineStatusList"),
	loading: LoadingComponent
});

const ETraining = Loadable({
	loader: () => import("components/etraining/ETrainingPage"),
	loading: LoadingComponent
});


const QueryList = Loadable({
	loader: () => import("components/query/QueryList"),
	loading: LoadingComponent
});

const StudyInfo = Loadable({
	loader: () => import("components/study/StudyInfo"),
	loading: LoadingComponent
});

const UserInfo = Loadable({
	loader: () => import("components/user/info/UserInfo"),
	loading: LoadingComponent
});
const eCrf = Loadable({
	loader: () => import("components/ecrf/ECrf"),
	loading: LoadingComponent
});

const ECrfHistoryList = Loadable({
	loader: () => import("components/ecrf/ECrfHistoryList"),
	loading: LoadingComponent
});

const SubjectPdf = Loadable({
	loader: () => import("components/subjectpdf/SubjectPdf"),
	loading: LoadingComponent
});

const SubjectPdfHistory = Loadable({
	loader: () => import("components/subjectpdf/SubjectPdfHistoryList"),
	loading: LoadingComponent
});

const DBLock = Loadable({
	loader: () => import("components/dblock/DBLock"),
	loading: LoadingComponent
});

const Delivery = Loadable({
	loader: () => import("components/iwrs/delivery/Delivery"),
	loading: LoadingComponent
});

const Blind = Loadable({
	loader: () => import("components/iwrs/blind/BlindList"),
	loading: LoadingComponent
});

const ListUpload = Loadable({
	loader: () => import("components/iwrs/listUpload/ListUpload"),
	loading: LoadingComponent
});

const EconsentHistoryList = Loadable({
	loader: () => import("components/econsent/EconsentHistoryList"),
	loading: LoadingComponent
});

const EcsHistoryList = Loadable({
	loader: () => import("components/ecsservice/EcsHistoryList"),
	loading: LoadingComponent
});

const SHIFT_KEY_CODE = 16;
const CRTL_KEY_CODE = 17;

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			studyId: null,
			roleId: null,
			currentUser: null,
			studySetting: null,
			isAuthenticated: (localStorage.getItem(ACCESS_TOKEN)) ? true : false,
			role: { id: 0, name: null },
			header: "",
			timeOut: null,
			isLoading: false
		}
		this.handleLogout = this.handleLogout.bind(this);
		this.loadCurrentUser = this.loadCurrentUser.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.initLocale = this.initLocale.bind(this);
		this.initNotification = this.initNotification.bind(this);
		this.timeOut = null;
		this.events = [
			// "load",
			"click",
			"scroll",
			"keypress",
			"contextmenu"
		];
		for (var i in this.events) {
			window.addEventListener(this.events[i], this.resetTimeout);
		}

		window.onkeyup = (e) => {
			if(e.keyCode == CRTL_KEY_CODE || e.keyCode == SHIFT_KEY_CODE){
				this.resetTimeout();
			}
		}

		window.addEventListener('storage', (e) => {
			if(e.key != "timeToLogout"){
				return;
			}

			if(e.newValue == null){
				this.handleLogout();
				return;
			}	
			let self = this;
			let timeOut = this.timeOut;
			let timeToLogout = localStorage.getItem("timeToLogout");
			clearTimeout(this.timeOut);
			timeOut = setTimeout(function () {
				self.handleLogout();
			}, Math.abs(timeToLogout));
			this.timeOut = timeOut;
		});
		
	}

	async componentDidMount() {
		this.initNotification();
		this.initLocale();
		await this.loadCurrentUser();

		const url = window.location.pathname;
		var regExp = /\/study\/[\d]+/g;
		let array = url.match(regExp);
		if (array) {
			let studyId = parseInt(array[0].replace('/study/', ''))
			let roleId = parseInt((url.match(/\/role\/[\d]+/g)[0]).replace('/role/', ''))
			this.setState(Object.assign(this.state, { studyId: studyId, roleId: roleId }));
			console.log("APP.JS : studyId", { studyId, roleId })
			await this.setStudyRole(studyId, roleId);
			this.loadStudySetting(studyId);
		}

		if (url === "/" || url === "/404") {
			this.resetTimeout();
		}
	}

	async componentWillReceiveProps(nextProps) {
		const url = window.location.pathname;
		const { studySetting } = this.state
		var regExp = /\/study\/[\d]+/g;
		let array = url.match(regExp);

		if (array) {
			let studyId = parseInt(array[0].replace('/study/', ''));
			let roleId = parseInt((url.match(/\/role\/[\d]+/g)[0]).replace('/role/', ''));
			console.log("studySetting", studySetting)
			// Kiem tra su thay ve studyId && roleId
			if (this.state.studyId != studyId || this.state.roleId != roleId) {
				await this.setStudyRole(studyId, roleId);
			}
			if (studySetting === null) {
				this.loadStudySetting(studyId)
			} else {
				console.log("nextProps", nextProps)
				this.resetTimeout();
			}
		} else {
			this.setState({ studySetting: null }, () => {this.resetTimeout()});
		}
	}

	initLocale() {
		//locale uu tien local storage
		const localLocale = localStorage.getItem(SELECTED_LOCALE) ? localStorage.getItem(SELECTED_LOCALE) : DEFAULT_LOCALE;
		const sessionLocale = sessionStorage.getItem(SELECTED_LOCALE);
		const currentLocale = localStorage.getItem(ACCESS_TOKEN) ? localLocale : sessionLocale ? sessionLocale : localLocale;
		intl.init({
			currentLocale,
			locales: {
				[currentLocale]: require(`../locales/${currentLocale}/${currentLocale}`)
			}
		})
		.then(() => {
		});
	}

	resetTimeout = () => {
		const url = window.location.pathname;
		let regExp = /\/study\/[\d]+/g;
		let hasCheckTimeOut = url.match(regExp);
		if (url === "/" || url === "/user/info" || url === "/404") {
			hasCheckTimeOut = true;
		}

		if (hasCheckTimeOut) {
			let { studySetting } = this.state;
			let timeOut = this.timeOut;
			let autoLogoutTime = 30;
			let self = this;
			clearTimeout(timeOut);
			if (studySetting !== null) {
				autoLogoutTime = studySetting.autoLogoutTime;
			}
			let timeToLogout = (autoLogoutTime * 60) * 1000;
			timeOut = setTimeout(function () {
				self.handleLogout();
			}, timeToLogout);

			this.timeOut = timeOut;
			// change timeToLogout to fire storage event but keep timeToLogout by * +-1
			localStorage.setItem("timeToLogout", this.changeTimeOutLocalStorage(timeToLogout));
		}
	}

	changeTimeOutLocalStorage = (timeToLogout) => {
		let time = localStorage.getItem("timeToLogout");
		if(time != null) {
			if(time > 0){
				return 0 - timeToLogout;
			} else {
				return timeToLogout;
			}
		} else {
			return timeToLogout;
		}
	}

	initNotification() {
		notification.config({
			placement: 'topRight',
			top: 70,
			duration: 3,
		});
	}

	async loadCurrentUser() {
		window.currentUser = 0;
		this.setState({
			isLoading: true
		});
		await getCurrentUser()
			.then(response => {
				this.setState({
					currentUser: response,
					isAuthenticated: true,
					isLoading: false
				});
				console.log("currentUser", response)
				window.currentUser = response.id;
				localStorage.setItem(CURRENT_USER, response.id);
				const now = moment()
				let pwResetExpired = moment(response.pwResetExpired)
				let pwChangedFlag = new Date(response.pwLastchanged);

				if (response.pwChangedFlag === null) {
					return this.props.history.push("/change-password");
				} else if (response.pwMustChangedFlag === 0) {
					return this.props.history.push("/change-password");
				} else if (now > pwResetExpired) {
					return this.props.history.push("/change-password");
				} else if (compareTwoDate(currentDateTime(), pwChangedFlag) >= 90) {
					console.log("must change pwd (expried 90d)")
					return this.props.history.push("/change-password");
				}
			}).catch(error => {
				localStorage.removeItem(ACCESS_TOKEN);
				this.setState({
					isLoading: false
				});
				return this.props.history.push("/login");
			});
	}

	loadStudySetting = (studyId) => {
		this.setState({
			isLoading: true
		});
		getStudySetting(studyId).then(respose => {
			this.setState({
				isLoading: false,
				studySetting: respose
			})
			console.log("loadStudySetting")
			this.resetTimeout();
		}).catch(error => {
			this.setState({
				isLoading: false
			});
		});
	}

	/**
	 * @author vungpv@ominext.com
	 * @param roleId
	 * @override state role
	 */
	setStudyRole = async (studyId, roleId) => {
		let that = this
		if (typeof studyId !== 'undefined' && typeof roleId !== 'undefined') {
			CommonApi.getStudyRole(studyId, roleId).then(data => {
				const iwrsLinkFlag = data.study.iwrsLinkFlag;
				let iwrsSetting = data.iwrsSetting;
				if(iwrsSetting != null){
					iwrsSetting.iwrsLinkFlag = iwrsLinkFlag;
				}
				
				localStorage.setItem(`studyIdText_${studyId}`, data.study.studyIdText);
				localStorage.setItem(`${IWRS}_${studyId}`, JSON.stringify(iwrsSetting));
				let role = { id: data.studyRole.id, name: data.studyRole.name }
				that.setState(Object.assign(that.state, { role: role, header: data.study.studyIdText }))
			})
		} else {
			that.setState(Object.assign(that.state, { role: { id: 0, name: null }, header: null }))
		}
	}

	handleLogout(redirectTo = "/", notificationType = "success", description = "You're successfully logged out.") {
		localStorage.removeItem(ACCESS_TOKEN);
		localStorage.removeItem('currentStudy');
		localStorage.removeItem(CURRENT_USER);
		localStorage.removeItem('timeToLogout');
		clearTimeout(this.timeOut);
		window.currentUser = 0;
		for (var key in localStorage) {
			if (key.includes('actions_') || key.includes('privilege_')) {
				localStorage.removeItem(key);
			}
		}

		this.setState({
			currentUser: null,
			isAuthenticated: false
		});
		FilterCacheApp.clear();
		this.props.history.push("/login");
	}

	handleLogin() {
		const defaultLocale = sessionStorage.getItem(SELECTED_LOCALE) ? sessionStorage.getItem(SELECTED_LOCALE) : DEFAULT_LOCALE;
		localStorage.setItem(SELECTED_LOCALE, defaultLocale);
		this.loadCurrentUser();
		localStorage.removeItem(NOTIFICATION_ID_DISPLAYED_LIST);
		this.props.history.push("/");
		this.resetTimeout();
	}

	render() {
		if (this.state.isLoading) {
			return <div></div>
		}

		let currentLocale = (sessionStorage.getItem('selectedLocale')) ? sessionStorage.getItem('selectedLocale') : DEFAULT_LOCALE;
		let locale = currentLocale === DEFAULT_LOCALE ? jaJP : enUS;
		jaJP.DatePicker.lang.today = intl.get("TODAY");
		return (
			<ConfigProvider locale={locale}>
				<Switch>
					<MainLayoutRoute exact path="/"
						authenticated={this.state.isAuthenticated}
						component={Study}
						handleLogout={this.handleLogout}
						currentUser={this.state.currentUser}
						intl={intl}
						titleContainer={"CDMS200TT001"}
						page={"HOME"}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/e-training/slide"
						authenticated={this.state.isAuthenticated}
						component={ETrainingSlide}
						handleLogout={this.handleLogout}
						currentUser={this.state.currentUser}
						intl={intl}
						titleContainer={null}
						page={"HOME"}
						menuActive={LISTMENU.ETRAINING}
					/>


					<MainLayoutRoute exact path="/user"
						component={SubjectList}
						intl={intl}
						authenticated={this.state.isAuthenticated}
					/>

					<MainLayoutRoute exact path="/user/info"
						component={UserInfo}
						intl={intl}
						handleLogout={this.handleLogout}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						page={"HOME"}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/subject"
						component={SubjectList}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						menuActive={LISTMENU.SUBJECT}
						containerWrapClass={'page-lst-subjects'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/site/:siteId/subject-create"
						component={Page}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						currentUser={this.state.currentUser}
						handleLogout={this.handleLogout}
						showMenu={true}
						menuActive={LISTMENU.SUBJECT}
						containerWrapClass={'wrap subject-page'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/site/:siteId/subject-no/:subjectNo/matrix/:matrixId"
						component={Page}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						currentUser={this.state.currentUser}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'wrap subject-page'}
						menuActive={LISTMENU.SUBJECT}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/site/:siteId/subject-no/:subjectNo/visit-matrix"
						component={VisitMatrix}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						currentUser={this.state.currentUser}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'visit-status-intro-page'}
						menuActive={LISTMENU.SUBJECT}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/user"
						component={StudyUser}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-user-management'}
						page={'USER'}
						menuActive={LISTMENU.ADMIN_USER}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/exam-info"
						component={StudyInfo}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-information'}
						menuActive={LISTMENU.ADMIN_INFO}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/user/add"
						component={StudyUserAdd}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-user-management'}
						menuActive={LISTMENU.ADMIN_USER}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/user/edit/:studyUserId"
						component={StudyUserEdit}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-user-management'}
						menuActive={LISTMENU.ADMIN_USER}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/site"
						component={SiteList}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'page-site-management'}
						menuActive={LISTMENU.ADMIN_SITE}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/site-register"
						component={SiteRegister}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'box-site-register'}
						menuActive={LISTMENU.ADMIN_SITE}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/site/:siteId/site-edit"
						component={SiteEdit}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'box-site-register'}
						menuActive={LISTMENU.ADMIN_SITE}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/status-visit"
						component={VisitStatus}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'page-site-management'}
						menuActive={LISTMENU.STATUS_VISIT}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/status-subject"
						component={SubjectStatus}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						menuActive={LISTMENU.STATUS_SUBJECT}
						containerWrapClass={'page-site-management'}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/status-site"
						component={SiteStatus}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'page-site-management'}
						menuActive={LISTMENU.STATUS_SITE}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/e-training"
						component={ETraining}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						currentUser={this.state.currentUser}
						showMenu={true}
						menuActive={LISTMENU.ETRAINING}
						containerWrapClass={'page-training'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/system-e-training"
						component={ETraining}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						currentUser={this.state.currentUser}
						showMenu={true}
						menuActive={LISTMENU.ETRAINING}
						containerWrapClass={'page-training'}
					/>

					<LoginLayoutRoute
						exact path="/login"
						component={Login}
						onLogin={this.handleLogin}
					/>

					<ForgetLayoutRoute
						exact path="/user/forget-login-id"
						component={ForgetLoginId}
						intl={intl}
						containerWrapClass={'forgot-password'}
						page={"FORGET"}
					/>

					<ForgetLayoutRoute
						exact path="/user/forget-password"
						component={ForgetPassword}
						intl={intl}
						containerWrapClass={'forgot-password'}
						page={"FORGET"}
					/>

					<MainLayoutRoute
						exact path="/change-password"
						component={PasswordSetting}
						handleLogout={this.handleLogout}
						currentUser={this.state.currentUser}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						containerWrapClass={'forgot-password'}
						page={"CHANGE_PWD"}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/page-status"
						component={PageStatus}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-site-management'}
						page={'USER'}
						menuActive={LISTMENU.STATUS_PAGE}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/block-status"
						component={BlockStatus}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-site-management'}
						page={'USER'}
						menuActive={LISTMENU.STATUS_BLOCK}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/question-status"
						component={QuesionStatus}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-site-management'}
						page={'QUESTION-STATUS'}
						menuActive={LISTMENU.STATUS_QUESTION}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/line-status"
						component={LineStatus}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-site-management'}
						page={'LINE-STATUS'}
						menuActive={LISTMENU.STATUS_LINE}
					/>

					{/*DATA MENU*/}
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/user-history-report"
						component={UserHistoryReport}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						menuActive={LISTMENU.USER_HISTORY_REPORT}
						containerWrapClass={'page-site-management'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/download-dataset"
						component={Dataset}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'page-download'}
						menuActive={LISTMENU.DATA_SET}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/download-dataset-history"
						component={DatasetHistory}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						containerWrapClass={'page-download'}
						menuActive={LISTMENU.DATA_SET}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/query"
						component={QueryList}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-lst-query'}
						menuActive={LISTMENU.QUERY}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/eCrf"
						component={eCrf}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						containerWrapClass={'page-site-management'}
						menuActive={LISTMENU.ECRF}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/ecrf-history"
						component={ECrfHistoryList}
						intl={intl}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						currentUser={this.state.currentUser}
						menuActive={LISTMENU.ECRF}
						containerWrapClass={'page-site-management'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/subject-pdf"
						component={SubjectPdf}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						menuActive={LISTMENU.SUBJECT_PDF}
						containerWrapClass={'page-site-management'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/subject-pdf-history"
						component={SubjectPdfHistory}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						showMenu={true}
						menuActive={LISTMENU.SUBJECT_PDF}
						containerWrapClass={'page-download'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/dblock"
						component={DBLock}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						menuActive={LISTMENU.DB_LOCK}
						showMenu={true}
						containerWrapClass={'page-information page-db-lock'}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/list-upload/:tabId"
						component={ListUpload}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						menuActive={IWRS_MENU.LIST_UPLOAD}
						showMenu={true}
						containerWrapClass={'page-list-upload'}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/delivery/:tabId"
						component={Delivery}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						menuActive={IWRS_MENU.DELIVERY_DRUG}
						showMenu={true}
						containerWrapClass={'page-list-upload'}
					/>
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/blind-list"
						component={Blind}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						menuActive={IWRS_MENU.BLIND_LIST}
						showMenu={true}
						containerWrapClass={'page-list-upload'}
					/>

					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/econsent-history"
						component={EconsentHistoryList}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						menuActive={LISTMENU.ECONSENT}
						showMenu={true}
						containerWrapClass={'econsent-subject-history'}
					/>
					
					<MainLayoutRoute exact path="/study/:studyId/role/:roleId/ecs-history"
						component={EcsHistoryList}
						intl={intl}
						currentUser={this.state.currentUser}
						authenticated={this.state.isAuthenticated}
						handleLogout={this.handleLogout}
						menuActive={LISTMENU.ECS_SERVICE}
						showMenu={true}
						containerWrapClass={'page-ecs-history'}
					/>

					<Route path="/force-refresh" component={null} key="force-refresh" />
					<Route path="/404" component={NotFound}></Route>
					<Route render={() => <Redirect to={{pathname: "/404"}} />} />
				</Switch>
			</ConfigProvider>
		);
	}
}

export default withRouter(App);
