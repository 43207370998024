module.exports = ({
    "CDMS600MSG001": "選択したクエリーをクローズします。\nよろしいですか。",
    "CDMS600MSG002": "クエリーの内容を入力してください。",
    "CDMS600MSG003": "選択したクエリーを再オープンします。\nよろしいですか。",
    "CDMS600MSG004": "選択したクエリーの内容を編集します。\nよろしいですか。",
    
    "CDMS600BTN001": "一括処理",
    "CDMS600BTN002": "実行",

    "CDMS600H001": "連番",
    "CDMS600H002": "施設名",
    "CDMS600H003": "被験者番号",
    "CDMS600H004": "Visit",
    "CDMS600H005": "ぺージ",
    "CDMS600H006": "ブロック",
    "CDMS600H007": "行番号(SEQ)",
    "CDMS600H008": "項目",
    "CDMS600H009": "ステータス",
    "CDMS600H010": "チェック種別",
    "CDMS600H011": "クエリー内容",
    "CDMS600H012": "回答内容",
    "CDMS600H013": "発行者",
    "CDMS600H014": "最終更新日時",

    "CDMS600TT001": "クエリー管理",
    "CDMS600TT002": "一括処理",

    "CDMS600OPT001": "クエリー終了",
    "CDMS600OPT002": "クエリー再オープン",
    "CDMS600OPT003": "クエリー内容編集",
    "CDMS600OPT004": "オープン",
    "CDMS600OPT005": "クローズ",
    "CDMS600OPT006": "回答済み",
    "CDMS600OPT007": "システム",
    "CDMS600OPT008": "マニュアル",
    "CDMS600OPT009": "システム",
});