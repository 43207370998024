module.exports = ({
    "CDMS103P001": "現在のメールアドレスを入力してください",
    "CDMS103P002": "ユーザー名称を入力してください",
    "CDMS103P003": "新しいパスワードを入力してください",
    "CDMS103P004": "新しいパスワードの確認を入力してください",
    "CDMS101P002": "秘密の答えを入力してください",

    "CDMS103MSG001": "現在のメールアドレスを入力してください。",
    "CDMS103MSG002": "入力された現在のメールアドレスがすでに使用されています。",
    "CDMS103MSG003": "ユーザー名称を入力してください。",
    "CDMS103MSG004": "以前に登録されたパスワードと同様のパスワードが設定されています。",
    "CDMS103MSG005": "パスワードは半角で8文字以上（英字と数字と特殊文字の組み合わせ）を入力してください。",
    "CDMS103MSG006": "パスワードが一致しません。",
    "CDMS103MSG007": "新しいパスワードを入力してください。",
    "CDMS103MSG008": "新しいパスワードの確認を入力してください。",
    "CDMS101MSG003": "秘密の質問に対する回答を入力してください。",

    "CDMS103BTN001": "前の画面に戻る",

    "CDMS103LB001": "ユーザーID",
    "CDMS103LB002": "現在のメールアドレス",
    "CDMS103LB003": "ユーザー名称",
    "CDMS103LB004": "新しいパスワード",
    "CDMS103LB005": "新しいパスワードの確認",
    "CDMS103LB006": "秘密の質問",
    "CDMS103LB007": "秘密の答え",

    "CDMS103TT001": "ユーザー情報",
    "CDMS103TT002": "基本情報",
    "CDMS103TT003": "パスワード更新",
    "CDMS103TT004": "秘密質問更新",

    "CDMS104TT001": "パスワード更新",
    "CDMS104LB001": "ユーザーID",
    "CDMS104LB002": "メールアドレス",
    "CDMS104LB003": "新しいパスワード",
    "CDMS104LB004": "パスワードの確認",
    "CDMS104LB005": "秘密の質問と答えを選択してください",
    "CDMS104LB006": "秘密の質問",
    "CDMS104LB007": "秘密の答え",
});